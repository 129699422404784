import { Link } from 'react-router-dom';
import { Avatar, Image } from 'antd';

import testImg from '../../../assets/blog/tblog1.jpg';
import blogger from '../../../assets/images/blogger.jpg';


const RelatedPost = ({ headerStyles, styles, imgStyles }) => {
    return (    
        <div className={`flex ${headerStyles ? headerStyles : ''} flex-col lg:flex-row`}>
            <div className={`md:basis-2/6 lg:basis-5/12 h-full`}>
                <img src={testImg} alt="" className={`w-full h-64 md:h-full object-cover rounded-tl-md rounded-bl-md`} />
            </div>
            <div className={`md:basis-4/6 lg:basis-7/12 p-7 flex flex-col justify-center ${styles ? styles : 'bg-gray-200' } rounded-tr-md rounded-br-md`}>
                <div className="mb-3 text-888 text-1xl">
                    <Link to="#" className="font-semibold text-black hover:text-black">Business</Link>, <Link to="#" className="font-semibold text-black hover:text-black">Travel</Link> —
                    <span className="date">July 2, 2020</span>
                </div>
                <div className='mb-3 text-justify'>
                    <Link to="#" className="block text-2xl font-semibold mb-3 hover:text-black">Your most unhappy customers are your greatest source of learning.</Link>
                </div>
                <div className="flex items-center space-x-2">
                    <div className="">
                        <Avatar 
                            src={<Image src={blogger} />} 
                            size={{ xs: 30, sm: 32, md: 40, lg: 43, xl: 45, xxl: 50 }}
                        />
                    </div>
                    <div className="text-1xl">
                        <small className='text-888'>Author</small>
                        <p className="font-semibold">Sergy Campbell</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default RelatedPost;