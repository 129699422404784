import { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
// import { Menu, Dropdown, Button } from 'antd';
import { GiHamburgerMenu } from 'react-icons/gi';
// import {  } from 'react-icons/fa';
import navLogo from '../assets/logos/ALins logo.jpg';
import './styles/nav.css';


const NavMenu = props => {
  const closeMenu = function(e){
    // console.log(e.target.classList);
    if (e.target.classList.contains('nav-menu') && props.isOpen){
      props.setIsOpen(false);
    }
  };

  useEffect(() => {
    // event listener to remove backdrop
    document.addEventListener('click', closeMenu);

    // cleanup
    return () => document.removeEventListener('click', closeMenu); 
  }, [props.isOpen]);


  return (
    <nav className={`nav-menu ${props.isOpen ? 'open' : ''}`}>
      <div className={`nav-menu-nav-wrapper ${props.isOpen ? 'open' : ''}`}>
        <Link 
          to='#'
          className='nav-close-btn block lg:hidden rounded bg-app-red text-white focus-within:text-white py-1 px-3 absolute top-4 right-4 shadow-md' 
          onClick={() => props.setIsOpen(false)}
        >
          X
        </Link>
        <ul className='nav-menu-nav flex relative items-center lg:py-0'>
          {props.children}
        </ul>
      </div>
    </nav>
  );
}

const NavItem = props => {
    return (
      <li className={`nav-item list-none mx-5 text-lg`}>
        <NavLink 
          to={`${props.path ? props.path : '#'}`} 
          className={`nav-link ${props.classNames ? `${props.classNames} dark:${props.classNames} hover:${props.classNames}` : 'hover:text-gray-800 dark:text-white dark:hover:text-slate-200'}`}>
            {props.children}
        </NavLink>
      </li>
    )
}

const NavDropDown = props => {
  return (
    <li className={`list-none mx-5 text-lg relative group`}>
      <NavLink 
        to='#' 
        className={`nav-link capitalize text-white lg:text-black lg:hover:text-black hover:text-slate-200`}>
          {props.title}
      </NavLink>
      <div className='absolute top-10 -left-24 lg:left-auto right-0 mx-auto h-0 overflow-hidden group-focus-within:h-auto group-focus-within:shadow-md bg-gray-700 lg:bg-white w-64 lg:w-48 z-20 group-focus-within:border-2 border-gray-700 lg:border-gray-100 transition-all ease-in'>
        {props.children}
      </div>
    </li>
  )
}

const NavDropdownItem = props => {
  return (
    <NavLink 
      to={`${props.path ? props.path : '#'}`} 
      className={`nav-link block text-center ${props.classNames ? props.classNames : ''} text-white lg:text-black lg:hover:text-black hover:text-slate-200`}>
        {props.children}
    </NavLink>
  )
}


const Nav = () => {
    const [navOpen, setNavOpen] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
      if (navOpen)
        setNavOpen(false);
    }, [pathname]);

    return ( 
      <nav className='px-10'>
          <div className="container h-full mx-auto flex justify-between items-center relative">
              <div className='nav-header flex justify-between items-center relative'>
                  <Link to='/' className='block'>
                      <img className='nav-logo' src={navLogo} alt='nav logo' height='250px' width='250px' />
                  </Link>
                  <Link to='#' onClick={() => setNavOpen(true)} 
                    className={`nav-toggler hidden text-3xl font-semibold text-black hover:text-black`}
                  >
                    <GiHamburgerMenu />
                  </Link>
              </div>
              <NavMenu isOpen={navOpen} setIsOpen={setNavOpen}>
                  <NavItem path='/'>Home</NavItem>
                  <NavItem path='/blog'>Blog</NavItem>
                  <NavItem path='/news'>News</NavItem>
                  <NavItem path='/QABank'>Q{'&'}A Bank</NavItem>
                  <NavItem path='/adverts'>Adverts</NavItem>
                  <NavItem path='/movies'>Movies</NavItem>
                  {/* <Dropdown overlay={DropownList} placement="bottom">
                    <Button className="text-lg p-0 border-0 hover:text-black hidden lg:block">Others</Button>
                  </Dropdown> */}
                  <NavDropDown title='others'>
                    <NavDropdownItem path='/mind-maps' classNames='pt-2 pb-3 border-b-2 border-gray-600 lg:border-gray-100'>Mind Maps</NavDropdownItem>
                    <NavDropdownItem path='/faqs' classNames='py-3 border-b-2 border-gray-600 lg:border-gray-100'>FAQs</NavDropdownItem>
                    <NavDropdownItem path='/contact' classNames='pt-3 pb-2'>Contact</NavDropdownItem>
                  </NavDropDown>
              </NavMenu>
          </div>
      </nav>
    );
}
 
export default Nav;