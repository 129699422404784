import { createContext, useContext, useEffect } from 'react';
import { Navigate, useLocation,  } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query'

import { useSelector, useDispatch } from 'react-redux';
import { updateToken, reset } from '../reducers/auth/authSlice';

import { toast } from 'react-toastify';


// create global query client instance
const queryClient = new QueryClient();

// create global context for global data management
export const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
}


const AuthProvider = ({ children }) => {
    const dispatch  = useDispatch();
    const { user, loginCount, auth_tokens, tokenRefreshing, tokenRefreshError, message } = useSelector(state => state.auth);

    useEffect(() => {
        // this should run once, when a user enters the site freshly
        // if auth token still in localStorage, update it
        if (loginCount >= 2 && auth_tokens){
            // console.warn("Dispatching updateToken action");
            // console.log("Updating auth token due to page refresh or fresh load");
            dispatch(updateToken(null));
        }
    }, []);
    
    useEffect(() => {
        // for authentication
        if (tokenRefreshError) {
            toast.error(message);
            dispatch(reset());
        }

        let tokenRefreshDuration = 1000 * 60 * 60 * 24; // 24 hrs - prod time
        // let tokenRefreshDuration = 1000 * 60 * 2; // 2 mins - dev time

        let interval = setInterval(() => {
            // console.warn("Running on load");
            // console.log("Auth Token: ", auth_tokens);
            if(auth_tokens){
                // console.log("About to execute token refresh");
                dispatch(updateToken(null));
            }
        }, tokenRefreshDuration);

        return () => clearInterval(interval);
    }, [tokenRefreshing, auth_tokens]);


    const contextData = {
        user,
        queryClient,
        auth_tokens, 
    };

    return ( 
        <AuthContext.Provider value={contextData}>
            <QueryClientProvider client={queryClient}>
                { children }            
            </QueryClientProvider>
        </AuthContext.Provider>
    );
}

const ProtectedView = ({ Component }) => {
    const location = useLocation();
    const { user } = useAuth();

    if (!user)
        return <Navigate to='/admin/accounts/login' state={{from: location}} replace />;
    return ( 
        <Component />
    );
}


export { AuthProvider, ProtectedView }