import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { motion } from 'framer-motion';
import { Avatar, Image } from 'antd';
import { BounceLoader } from 'react-spinners';

import { getBlog } from '../../../admin/dashboard/services/blogServices';
import RelatedPost from './RelatedPost';
import { BASE_URL } from '../../../constants';

import blogger from '../../../assets/images/blogger.jpg';
import blogImg2 from '../../../assets/blog/rblog2.jpg';
import blogImg3 from '../../../assets/blog/rblog3.jpg';
import blogImg4 from '../../../assets/blog/rblog4.jpg';
import blogImg5 from '../../../assets/blog/rblog5.jpg';


// A custom hook that builds on useLocation to parse
// the query string for you.
// function useQuery() {
//     const { search } = useLocation();
  
//     return React.useMemo(() => new URLSearchParams(search), [search]);
// }


const BlogDetail = () => {
    // const query = useQuery();
    const params = useParams();
    const [blogID, setBlogID] = useState(params.id);

    const { data:blog, status:blogStatus } = useQuery(['single-blog'], () => getBlog(blogID)); // use t cat_name - trending category

    // (TODO): think of how to render posts related to the category of the blog with the blogID

    useEffect(() =>{
        document.title = `ALins | Blog | ${blogStatus === 'loading' ? 'blog-title' : blog.title}`;
    }, []);

    return ( 
        <>
        {
            (blogStatus === 'loading')
            ?
            <div className='flex justify-center items-center mt-20 mb-40'>
                <BounceLoader 
                    color="#d43f3f"
                    size={140}
                />
            </div>
            :
            <section className="py-20">
                <div className='container mx-auto'>
                    <header className='text-center px-10 lg:px-5 xl:px-5'>
                        <div className='mb-4'>
                            <Avatar     
                                src={<Image src={blogger} />} 
                                size={{ xs: 40, sm: 40, md: 40, lg: 55, xl: 60, xxl: 85 }}
                            />
                            <div className="text-black font-bold mt-3 flex space-x-2 flex-wrap justify-center items-center">
                                { 
                                    blog.author?.length > 0 
                                    ?
                                    blog.author?.map((author, index) => {
                                        if (index === blog.author.length - 1){
                                            return (<React.Fragment key={index}>
                                                <Link to="#" className="font-semibold text-black hover:text-black capitalize">{author.name}</Link>
                                            </React.Fragment>)
                                        } else {
                                            return (<React.Fragment key={index}>
                                                <Link to="#" className="font-semibold text-black hover:text-black capitalize">{author.name},</Link>{' '}
                                            </React.Fragment>)
                                        }
                                    })
                                    :
                                    <p className='font-semibold text-black hover:text-black capitalize text-center'>Not Stated</p>
                                }
                            </div>
                            <small className='text-888'>{blog.date_created}</small>
                        </div>
                        <div>
                            <h2 className='text-black font-bold text-4xl mb-2'>{blog.title}</h2>
                            <p className='text-888'>
                                {blog.excerpt}
                            </p>
                        </div>
                    </header>
                    <article className="my-12 text-center md:text-left px-10 lg:px-5">
                        <img className="img-responsive mx-auto" src={`${BASE_URL}${blog.image}`} alt='' />
                        <p className="my-10 text-justify">
                           {blog.body}
                        </p>
                        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3'>
                            <motion.img 
                                className="img-responsive w-full" src={blogImg2} alt='' 
                            />
                            <motion.img 
                                className="img-responsive w-full" src={blogImg3} alt='' 
                            />
                            <motion.img 
                                className="img-responsive w-full" src={blogImg4} alt='' 
                            />
                            <motion.img 
                                className="img-responsive w-full" src={blogImg5} alt='' 
                            />
                        </div>
                    </article>
                
                </div>
            </section>
        }

        {/* render related posts */}
        <div className="bg-gray-200 py-20">
            <div className='container mx-auto'>
                <div className='popular-section px-10 lg:px-5'>
                    <h2 className="font-bold text-4xl text-center mb-8">Related Posts</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-10 gap-x-10">
                        <RelatedPost headerStyles={'shadow-md'} styles={'bg-white'} />
                        <RelatedPost headerStyles={'shadow-md'} styles={'bg-white'} />
                        <RelatedPost headerStyles={'shadow-md'} styles={'bg-white'} />
                        <RelatedPost headerStyles={'shadow-md'} styles={'bg-white'} />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
 
export default BlogDetail;