import { useState } from 'react';
// import { Link } from 'react-router-dom';
import { BsInstagram, BsTwitter, BsLinkedin } from 'react-icons/bs';
import { FaFacebookSquare, FaTiktok, FaYoutubeSquare } from 'react-icons/fa';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';
import { useForm } from "react-hook-form";
import * as Toast from '@radix-ui/react-toast';

import trademarkLogo from '../assets/logos/trademark_logo.png';
// import IGLogo from '../assets/logos/instagram_logo.png';
import { AppLogoText, BASE_URL } from '../constants';
import MyToast from './Toast';



const Footer = () => {
    let [formData, setFormData] = useState({
        email: ''
    });

    let [isLoading, setIsLoading] = useState(false);
    let [respMsg, setRespMsg] = useState('');
    let [showAlert, setShowAlert] = useState(false);
   
    const { register:RegisterField, handleSubmit:SubmitForm, formState: { errors } } = useForm(); // form validation

    const handleChange = e => {
            setFormData(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
        }));
    }

    const handleSubmit = () => {
        // login user
        // console.log("Submitting:", formData);

        const registerSubscriber = async () => {
            setIsLoading(true);

            const response = await fetch(`${BASE_URL}/api/subscribers/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            return response.json(); 
        }

        registerSubscriber()
            .then(data => { 
                setIsLoading(false); 
                setShowAlert(true);
                setRespMsg(data?.message || data?.email[0]);
                setTimeout(() => {
                    setFormData({ email: '' });
                }, 4000);
            })
            .catch(err => console.error(err));
    }
    

    return (
        <footer className="bg-gray-100 px-5 md:px-20 text-gray-800">
            <div className="container mx-auto flex flex-col md:flex-row flex-wrap py-16 space-y-8 md:space-y-4">
                <div className="flex flex-col basis-full md:basis-full lg:basis-1/2 xl:basis-1/3 p-2">
                    <img className='mx-auto lg:mx-0' src={trademarkLogo} alt='ALIns trademark logo' width={'250px'} height={'250px'}  />
                    <p className='mt-4 text-center lg:text-justify'>
                        Taking learning beyond the four walls of the classrooms; to the streets and everywhere, thereby making everyone fall in love with learning
                    </p>
                </div>
                <div className="flex flex-col justify-start items-center text-center basis-full md:basis-full lg:basis-1/2 xl:basis-1/3 p-2">
                    <div className="">
                        <h2 className='text-2xl text-gray-800 mb-3'>Follow Us</h2>
                        <p>Make sure to follow us</p>
                    </div>
                    <div className='flex justify-center flex-wrap md:flex-nowrap'>
                        <a 
                            href={'https://www.instagram.com/official_alins/'}
                            target="_blank"
                            rel="noopener noreferrer"
                            className='h-16 w-16 rounded-full flex justify-center items-center dark:bg-app-dark text-4xl text-gray-500 hover:text-gray-500'
                        >
                            <BsInstagram />
                        </a>
                        <a 
                            href={'https://twitter.com/official_alins?t=AeVMfqu-HP-scT486gIaQA&s=09'}
                            target="_blank"
                            rel="noopener noreferrer"
                            className='h-16 w-16 rounded-full flex justify-center items-center dark:bg-app-dark text-4xl text-gray-500 hover:text-gray-500'
                        >
                            <BsTwitter />
                        </a>
                        <a 
                            href={'https://www.facebook.com/ALins-101590569233524'} 
                            target="_blank"
                            rel="noopener noreferrer"
                            className='h-16 w-16 rounded-full flex justify-center items-center dark:bg-app-dark text-4xl text-gray-500 hover:text-gray-500'
                        >
                            <FaFacebookSquare />
                        </a>
                        <a 
                            href={'https://youtube.com/channel/UCvjr19C5mpJyqTNysfXHgMw'} 
                            target="_blank"
                            rel="noopener noreferrer"
                            className='h-16 w-16 rounded-full flex justify-center items-center dark:bg-app-dark text-4xl text-gray-500 hover:text-gray-500'
                        >
                            <FaYoutubeSquare />
                        </a>
                        <a 
                            href={'https://www.tiktok.com/@official_alins?lang=en'} 
                            target="_blank"
                            rel="noopener noreferrer"
                            className='h-16 w-16 rounded-full flex justify-center items-center dark:bg-app-dark text-4xl text-gray-500 hover:text-gray-500'
                        >
                            <FaTiktok />
                        </a>
                        <a 
                            href={'https://www.linkedin.com/in/alins-inspirar-278aa223a/'} 
                            target="_blank"
                            rel="noopener noreferrer"
                            className='h-16 w-16 rounded-full flex justify-center items-center dark:bg-app-dark text-4xl text-gray-500 hover:text-gray-500'
                        >
                            <BsLinkedin />
                        </a>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center p-2 basis-full xl:basis-1/3">
                    <div className="mb-5 text-center">
                        <h2 className='text-2xl text-gray-800 mb-3'>News Letter</h2>
                        <p className=''>Subscribe to Our Newsletter</p>
                    </div>
                    <form 
                        className="flex flex-col w-11/12 sm:w-9/12"
                        onSubmit={SubmitForm(handleSubmit)} 
                    >
                        <input 
                            type='text' 
                            {...RegisterField("email", { required: true, maxLength: 50 })} 
                            onChange={handleChange} 
                            className={`p-3 w-full border-solid border-2 border-gray-500 rounded-md shadow-sm
                                focus:border-gray-200 focus:ring-4 focus:ring-gray-200   
                                ${(errors.email?.type === 'required' || errors.email?.type === 'maxLength') ? 'focus:border-red-200 focus:ring-red-400' : '' } 
                                transition ease-in-out delay-150 bg-transparent`} 
                            value={formData.email}                        
                            placeholder='Email'  
                        />
                        {
                            errors.email?.type
                            ?
                                errors.email?.type === 'required'
                                ?
                                <small className={`mt-2 italic text-red-500`}>
                                    This field is required
                                </small>
                                :
                                <small className={`mt-2 italic text-red-500`}>
                                    Maximum e-mail length exceeded
                                </small>
                            :
                            <></>
                        }
                        <button 
                            disabled={isLoading ? true : false}
                            type='submit' 
                            className={`${!isLoading ? 'bg-app-red' : 'bg-app-red-opacity animate-pulse'} mt-2 w-full text-white p-3 rounded-md shadow-sm focus:ring-4 focus:ring-red-200 transition ease-in-out delay-150`}
                        >
                            {isLoading ? 'Submitting' : 'Subscribe'}
                        </button>
                    </form>
                </div>
            </div>
            <div className='basis-full flex space-x-2 justify-center border-t-2 border-opacity-5 py-6'><p>Copyright <AiOutlineCopyrightCircle className='inline-block' /> {new Date().getFullYear()};</p> <AppLogoText styles='text-gray-800' /></div>
            <MyToast 
                open={showAlert}
                setOpen={setShowAlert} 
                title={'Subscribers'} 
                description={
                    (respMsg === 'success')
                    ?
                    `${formData.email} successfully registered`
                    :
                    respMsg
                }
            />
        </footer>
    );
}

export default Footer;