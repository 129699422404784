import React from 'react';

import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

// layout
import Nav from '../components/Nav';
import Footer from '../components/Footer';

// pages
import Home from './home/Home';

import PageLoader from '../loaders/PageLoader';
import BlogDetail from './blog/components/BlogDetail';
import PageNotFound from './PageNotFound';
// import ScrollTop from '../components/ScrollTop';

const About = React.lazy(() => import ("./About"));
const Faqs = React.lazy(() => import ("./faqs/Faqs"));
const News = React.lazy(() => import ("./news/News"));
const Adverts = React.lazy(() => import ("./Adverts"));
const Contact = React.lazy(() => import ("./Contact"));
const Movies = React.lazy(() => import ("./movies/Movies"));
const MindMaps = React.lazy(() => import ("./MindMaps"));
const Blogs = React.lazy(() => import ("./blog/Blogs"));
const QABank = React.lazy(() => import ("./QABank"));


const Pages = () => {
    return ( 
        <>
            <Nav />
            <AnimatePresence exitBeforeEnter>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='news' element={
                        <React.Suspense fallback={<PageLoader />}>
                            <News />
                        </React.Suspense>
                    } />
                    <Route path='adverts' element={
                        <React.Suspense fallback={<PageLoader />}>
                            <Adverts />
                        </React.Suspense>
                    } />
                    <Route path='mind-maps' element={
                        <React.Suspense fallback={<PageLoader />}>
                            <MindMaps />
                        </React.Suspense>
                    } />
                    <Route path='blog' element={
                        <React.Suspense fallback={<PageLoader />}>
                            <Blogs />
                        </React.Suspense>
                    } />
                    <Route path='blog/:id/detail' element={
                        <React.Suspense fallback={<PageLoader />}>
                            <BlogDetail />
                        </React.Suspense>
                    } />
                    <Route path='about' element={
                        <React.Suspense fallback={<PageLoader />}>
                            <About />
                        </React.Suspense>
                    } />
                    <Route path='contact' element={
                        <React.Suspense fallback={<PageLoader />}>
                            <Contact />
                        </React.Suspense>
                    } />
                    <Route path='movies' element={
                        <React.Suspense fallback={<PageLoader />}>
                            <Movies />
                        </React.Suspense>
                    } />
                    <Route path='FAQs' element={
                        <React.Suspense fallback={<PageLoader />}>
                            <Faqs />
                        </React.Suspense>
                    } />
                    <Route path='QABank' element={
                        <React.Suspense fallback={<PageLoader />}>
                            <QABank />
                        </React.Suspense>
                    } />
                    <Route path='*' element={
                        <PageNotFound />
                    } />
                </Routes>
            </AnimatePresence>
            <Footer />
        </>
    );
}
 
export default Pages;