import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Routes, Route } from 'react-router-dom';

import DashNav from './DashNav';
import DashAsideNav from './DashAsideNav';
import { DashSpinnerLoader } from './loaders/DashLoaders';

const Home = React.lazy(() => import("./pages/DashHome"));
const ManageProfile = React.lazy(() => import ("./pages/ManageProfile"));
const ManageUsers = React.lazy(() => import ("./pages/ManageUsers"));
const ManageContacts = React.lazy(() => import ("./pages/ManageContacts"));
const ManageMovies = React.lazy(() => import ("./pages/ManageMovies"));
const ManageNews = React.lazy(() => import ("./pages/ManageNews"));
const ManageAdverts = React.lazy(() => import ("./pages/ManageAdverts"));
const ManageMindMaps = React.lazy(() => import ("./pages/ManageMindMaps"));
const ManageGenres = React.lazy(() => import ("./pages/ManageGenres"));
const ManageCategories = React.lazy(() => import ("./pages/ManageCategories"));
const ManageBlogs = React.lazy(() => import ("./pages/ManageBlogs"));
const ManageFaqs = React.lazy(() => import ("./pages/ManageFaqs"));
const ManageQAs = React.lazy(() => import ("./pages/ManageQA"));
const ManageAuthors = React.lazy(() => import ("./pages/ManageAuthors"));
const ManageSubscribers = React.lazy(() => import ("./pages/ManageSubscribers"));
const SendMail = React.lazy(() => import ("./pages/SendMail"));


const Dashboard = () => {
    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        document.title = `ALins | Dashboard`;
    }, []);

    return (  
        <section style={{height:'100vh', overflow: 'hidden'}} className='bg-gray-800 px-3 md:px-2'>
            <DashNav />
            <section style={{height: '85vh'}} className='pt-2 pb-5'>
                <div
                    className='container h-full mx-auto flex flex-col md:flex-row items-center relative'
                    style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
                >
                    {/* aside menu */}
                   <DashAsideNav user={user} />
                   {/* main section */}
                    <main className='bg-gray-100 shadow-lg overflow-y-auto h-full w-full basis-full md:basis-4/5 translate-y-0 md:translate-y-0 translate-x-0 md:translate-x-1/4 py-6 px-2 sm:px-5'>
                        <Routes>
                            <Route path="home" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <Home />
                                </React.Suspense>
                            } />  
                            <Route path="user/:id/profile" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageProfile />
                                </React.Suspense>
                            } />
                            <Route path="manage-users" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageUsers />
                                </React.Suspense>
                            } />  
                            <Route path="authors" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageAuthors />
                                </React.Suspense>
                            } />  
                            <Route path="genres" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageGenres />
                                </React.Suspense>
                            } />  
                            <Route path="categories" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageCategories />
                                </React.Suspense>
                            } />  
                            <Route path="blogs" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageBlogs />
                                </React.Suspense>
                            } />    
                            <Route path="news" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageNews />
                                </React.Suspense>
                            } />    
                            <Route path="adverts" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageAdverts />
                                </React.Suspense>
                            } />  
                            <Route path="mindmaps" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageMindMaps />
                                </React.Suspense>
                            } />    
                            <Route path="contacts" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageContacts />
                                </React.Suspense>
                            } />     
                            <Route path="movies" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageMovies />
                                </React.Suspense>
                            } />     
                            <Route path="faqs" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageFaqs />
                                </React.Suspense>
                            } />    
                            <Route path="QAs" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageQAs />
                                </React.Suspense>
                            } />    
                            <Route path="subscribers" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <ManageSubscribers />
                                </React.Suspense>
                            } />    
                            <Route path="send-mail" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <SendMail />
                                </React.Suspense>
                            } />    
                            {/* <Route path="spinner" element={
                                <React.Suspense fallback={<DashSpinnerLoader />}>
                                    <DashSpinnerLoader />
                                </React.Suspense>
                            } />    */}
                        </Routes>
                    </main>
                </div>
            </section>
        </section>
    );
}
 
export default Dashboard;