import { useState, useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

// import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';

import { login, reset } from '../reducers/auth/authSlice';



const Login = () => {
    let [showPassword, setShowPassword] = useState(false);
    let [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    const { register:RegisterField, handleSubmit:SubmitForm, formState: { errors } } = useForm(); // form validation

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch  = useDispatch();

    const from = location.state?.from?.pathname;

    const { user, isLoading, isError, loginSuccess, logoutSuccess, message } = useSelector(state => state.auth);

    // for our show password button
    const showPasswordInput = useRef();

    useEffect(() => {
        document.title = `ALins | Admin Login`;
    }, []);

    useEffect(() => {
        if (isError){
            // console.log("This is where", message);
            toast.error(message);
            dispatch(reset());
        }

        if (loginSuccess){
            if (from) // navigate to protected view 
                navigate(from, { replace: true });
            toast.success(`Logged In: Welcome ${user?.username}`)
            dispatch(reset());
        }

        if (logoutSuccess) {
            // console.log("Logout message:", message);
            toast.success(message);
            dispatch(reset());
        }
    }, [isError, loginSuccess, logoutSuccess]);  
    // }, [user, isLoading, isError, loginSuccess, message, navigate, dispatch]);

    const handleShowPassword = (show) => {
        // change show password value and alter the tick on the checkbox input
        setShowPassword(show);
        showPasswordInput.current.checked = show;
        // console.log(showPasswordInput.current.checked);
    }

    const handleChange = e => {
        setFormData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const handleSubmit = () => {
        // login user
        dispatch(login(formData));
    }

    if (user)
        navigate('/admin/dashboard/home');
    return ( 
        <section style={{height: '100vh', width: '100vw'}} className="bg-gray-800 flex flex-col justify-center items-center">
            <div className='w-4/6 md:w-2/4 mx-auto py-3 px-1 md:px-3 text-center'>
                <h1 className='text-white text-3xl sm:text-4xl md:text-5xl mb-1 whitespace-nowrap'>AL<span className='text-app-red'>ins</span> Admin Site</h1>
            </div>
            <form 
                onSubmit={SubmitForm(handleSubmit)} 
                className='bg-white relative flex flex-col py-16 md:py-20 px-5 sm:px-8 md:px-10 w-10/12 sm:w-9/12 md:w-7/12 lg:w-6/12 xl:w-4/12 mx-auto border-2 rounded-md'
                style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
            > 
                <div className='form-sect mb-4 md:mb-3 lg:mb-5'>
                    <input 
                        type='text' 
                        {...RegisterField("username", {required: true, maxLength: 50})} 
                        onChange={handleChange} 
                        className={`p-3 w-full border-solid border-2 border-gray-500 rounded-md shadow-sm 
                                focus:border-gray-200 focus:ring-4 focus:ring-gray-200   
                                ${errors.username?.type === 'required' ? 'focus:border-red-200 focus:ring-red-400' : '' } 
                                transition ease-in-out delay-150 bg-transparent`} 
                        placeholder='Username'  
                    />
                </div>
                <div className='form-sect mb-4 md:mb-3 lg:mb-5'>
                    <input 
                        type={`${showPassword ? 'text' : 'password'}`} 
                        {...RegisterField("password", { required: true, maxLength: 255 })} 
                        onChange={handleChange} 
                        className={`p-3 w-full border-solid border-2 border-gray-500 rounded-md shadow-sm 
                                focus:border-gray-200 focus:ring-4 focus:ring-gray-200   
                                ${errors.password?.type === 'required' ? 'focus:border-red-200 focus:ring-red-400' : '' } 
                                transition ease-in-out delay-150 bg-transparent`} 
                        placeholder='Password'
                    />
                </div>
                <div className='form-sect mb-4 md:mb-3 lg:mb-5 flex justify-start items-center space-x-3'>
                    <input type='checkbox' ref={showPasswordInput} onClick={() => handleShowPassword(!showPassword)} />
                    <Link to='#' className='flex justify-start items-center space-x-3 text-black hover:text-black' onClick={() => handleShowPassword(!showPassword)}>
                        <p>Reveal Password</p>
                    </Link>
                </div>
                <button 
                    disabled={isLoading ? true : false}
                    type='submit' 
                    className={`${!isLoading ? 'bg-app-red' : 'bg-app-red-opacity animate-pulse'} text-white p-3 rounded-md shadow-sm focus:ring-4 focus:ring-red-200 transition ease-in-out delay-150`}
                >
                    {isLoading ? 'Submitting' : 'Login'}
                </button>
            </form>
            <div className='mt-5'>
                <p className='text-white'>Copyright <AiOutlineCopyrightCircle className='inline-block mx-1' /> {new Date().getFullYear()}; ALins</p>
            </div>
        </section>
    );
}
 
// const Toast = Swal.mixin({
//     toast: true,
//     position: 'top',
//     showConfirmButton: false,
//     timer: 3000,
//     timerProgressBar: true,
//     didOpen: (toast) => {
//       toast.addEventListener('mouseenter', Swal.stopTimer)
//       toast.addEventListener('mouseleave', Swal.resumeTimer)
//     }
// });

export default Login;