import { useEffect } from 'react';
import pnfSVG from '../assets/svgs/page_not_found.svg';


const PageNotFound = () => {
    useEffect(() => { 
        document.title = 'ALins | Page Not Found';
    });

    return (  
        <section className='pnf-section py-10'>
            <div className='bg-white m-auto flex flex-col justify-center items-center flex-column shadow-lg border-2 rounded py-20 px-10' style={{height: '100%', width: '85%'}}>
                <img className='svg-404 object-cover md:object-contain h-auto md:h-50vh w-full' src={pnfSVG} alt='Page Not Found' />
                <br/>
                <h1 className='text-center text-5xl md:text-6xl font-semibold text-gray-800'>Page Not Found</h1>
            </div>
        </section>
    );
}
 
export default PageNotFound;