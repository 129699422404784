import { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import introImg from '../../assets/images/intro-bg.jpg';
import missionImg from '../../assets/images/mission_img.jpg';
import objectivesImg from '../../assets/images/objectives_img.jpg';
import { AppLogoText } from '../../constants';
import './styles/home.css';


const Home = () => {
    useEffect(() => {
        document.title = `ALins | Home`;
    }, []);

    return ( 
        <>
            <header className="intro-header h-70vh overflow-hidden relative flex items-center">
                <img className="absolute top-0 left-0 h-full w-full object-cover" src={introImg} alt='banner img' />
                <div className="intro-header-content-wrapper container mx-auto text-center">
                    <div>
                        <AppLogoText styles='text-white text-7xl md:text-9xl' />
                        <p className="text-white mb-6 text-xl">
                            Making learning real fun.
                        </p>
                        {/* <Link 
                            to='#' 
                            className='inline-block py-3 px-10 bg-app-red hover:bg-app-red-opacity transition delay-75 text-white hover:text-white rounded-3xl text-[1rem] md:text-[1.2rem]'
                        >
                            Learn More
                        </Link> */}
                    </div>
                </div>
            </header>

            {/* briefing section */}
            <section className="py-28 px-10">
                <div className='container mx-auto'>
                    <div className='briefing-header text-center md:text-left'>
                        <h1 className="h1-underlined text-4xl md:text-5xl font-semibold relative">BRIEF OVERVIEW</h1>
                    </div>
                    <div className='text-justify'>
                        <p className='my-8'>ALins is an initiative that crisscrosses education and entertainment. ALins provides educational movies that simplifies the learning process thereby making learning fun for students across levels and disciplines. Judy Willis, a Neurologist, in her book ‘Research-based strategies to ignite student learning: Insights from a Neurologist and Classroom Teacher’, explained how such fun experiences can enhance learning via increasing the levels of dopamine, endorphins and oxygen.</p>
                        <p className='my-8'>Different researches have also shown how entertainment can aid learning. In simple parlance, ALins makes use of entertainment-education in passing messages across to learners. We wrap teachings around interesting movies, games and music to make the learning process both enjoyable and long-lasting in people’s minds. And what is more, we take teaching and learning to the streets, deploying day-to-day experiences in our educational movies.</p>
                    </div>
                </div>
            </section>
            
            {/* what we do */}
            <section className="bg-gray-800 py-28 px-10 text-slate-100">
                <div className='container mx-auto flex flex-col md:flex-row justify-between md:items-center'>
                    <div className='briefing-header text-center md:text-left py-1 px-3 basis-1/2 flex justify-center items-center'>
                        <img className='mb-10 md:mb-0 object-cover h-auto w-full md:max-w-full' src={missionImg} alt='mission img' />
                    </div>
                    <div className='text-center md:text-left py-1 px-3 basis-1/2'>
                        <div className='mb-6'>
                            <h1 className="h1-underlined text-4xl font-semibold relative text-white">VISION</h1>
                            <span className="block">The vision of ALins is:</span>
                            <p>“Making learning real fun; making you yearn for more”</p>
                        </div>
                        <div>
                            <h1 className="h1-underlined text-4xl font-semibold relative text-white">MISSION</h1>
                            <div>
                                <span className="block">The Mission of ALins is:</span>
                                <p>
                                    To take learning beyond the four walls of the classrooms; to the streets and everywhere, 
                                    thereby making everyone fall in love with learning, 
                                    understanding what{'’'}s being taught, and retaining what{'’'}s learnt, by leveraging the power of entertainment-education.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* aims & objectives */}
            <section className="py-28 px-10 text-gray-800 bg-gray-100">
                <div className='container mx-auto flex flex-col-reverse md:flex-row justify-between md:items-center'>
                    <div className='text-center md:text-left py-1 px-3 basis-1/2'>
                        <div className='mb-6'>
                            <h1 className="h1-underlined text-4xl font-semibold relative text-gray-800">AIMS {'&'} OBJECTIVES</h1>
                            <p>The following are the aims and objectives of ALins:</p>
                            <ul className='objectives-list'>
                                <li>To offer learners a double advantage of learning and entertainment</li>
                                <li>To deploy stories, music, and other relevant means that will make what is being learnt stick to the mind of students</li>
                                <li>To break down seemingly abstract concepts to forms much easily understood by students</li>
                                <li>To make even passive learners (those not in a particular field of study) garner some life-applicable lessons from our educational movies.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='briefing-header text-center md:text-left py-1 px-3 basis-1/2 flex justify-center items-center'>
                        <img className='mb-10 md:mb-0 object-cover h-auto w-full md:max-w-full' src={objectivesImg} alt='objectives img' />
                    </div>
                </div>
            </section>

            {/* company-info section */}
            <section className="bg-gray-800 py-28 text-center px-10 text-white">
                <div className="container mx-auto">
                    <h1 className="text-4xl md:text-5xl mb-6 font-semibold text-white">MANAGEMENT OF THE COMPANY</h1>
                    <p className="text-center">
                        The Company is managed by three founders, two of whom are educationists and entrepreneurs and the third is a commonwealth scholar. 
                        Each department of the company is also headed by a professional. We also have Advisors from different walks of life.
                    </p>
                </div>
            </section>
        </>
    );
}
 
export default Home;