import axios from 'axios';
import { BASE_URL } from '../../../constants';


/***
    ****************************************************************
    For Blogs: CRUD triggers for the Blog Model
    ****************************************************************
***/
export const fetchBlogs = async () => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const request = await axios.get(`${BASE_URL}/api/blogs`, config);
    return request.data;
}

export const fetchBlogsByCategory = async (category, count=4) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const request = await axios.get(`${BASE_URL}/api/blogs?category=${category}&count=${count}`, config);
    return request.data;
}

export const fetchBlogsByPage = async (page=0) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const request = await axios.get(`${BASE_URL}/api/blogs-list?page=${page}`, config);
    return request.data;
}

export const getBlog = async (blog_id) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const request = await axios.get(`${BASE_URL}/api/blogs/${blog_id}/`, config);
    return request.data;
}

export const postBlog = async (form_data) => {
    const config = {
        headers: {
            'Authorization': `JWT ${JSON.parse(localStorage.auth_tokens).access}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    const request = await axios.post(`${BASE_URL}/api/blogs/`, form_data, config);
    return request.data;
}

export const updateBlog = async (form_data) => {

    const config = {
        headers: {
            'Authorization': `JWT ${JSON.parse(localStorage.auth_tokens).access}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    let id = form_data.get('id');
    form_data.delete('id');

    const request = await axios.patch(`${BASE_URL}/api/blogs/${id}/`, form_data, config);
    return request.data;
}

export const deleteBlog = async (id) => {

    const config = {
        headers: {
            'Authorization': `JWT ${JSON.parse(localStorage.auth_tokens).access}`,
            'Content-Type': 'application/json'
        }
    };

    const request = await axios.delete(`${BASE_URL}/api/blogs/${id}/`, config);
    return request.data;
}