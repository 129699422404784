import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import { store } from './store';

import { MainAppProvider } from './context/MainContext';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Pages from "./pages/Pages";
import Dashboard from "./admin/dashboard/Dashboard";
import Login from './admin/Login';
import { AuthProvider, ProtectedView } from './context/AuthContext';
import ScrollTop from './components/ScrollTop';


function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollTop />
        <Routes>
          {/* Main App Provider Context provides the frontend(user side) with the query client instance */}
          <Route path='/*' element={ <MainAppProvider><Pages /></MainAppProvider> } />

          {/* Wrap with ProtectedView */}
          {/* Auth Provider Context provides the backend(admin side) with the query client instance and auth credentials */}
          <Route path='admin/*' element={
            <AuthProvider>
              <Routes>
                <Route path='dashboard/*' element={<ProtectedView Component={Dashboard} />} />
                <Route path='accounts/login/' element={
                  <Login />
                } />
              </Routes>
            </AuthProvider>
          } />
        </Routes>
      </Router>
     <ToastContainer />
    </Provider>
  );
}

export default App;
