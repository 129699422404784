/***
    ****************************************************************
    For Users: CRUD triggers for the User Model
    ****************************************************************
***/

import axios from 'axios';
import { BASE_URL } from '../../../constants';


export const fetchUsers = async () => {
    const auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));

    const config = {
        headers: {
            'Authorization': `JWT ${auth_tokens.access}`,
            'Content-Type': 'application/json'
        }
    };

    // make request to get user
    const request = await axios.get(`${BASE_URL}/api/auth/users/`, config);
    return request.data;
}

export const getUser = async (user_id) => {
    const auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));

    const config = {
        headers: {
            'Authorization': `JWT ${auth_tokens.access}`,
            'Content-Type': 'application/json'
        }
    };

    // make request to get user
    const request = await axios.get(`${BASE_URL}/api/auth/user/${user_id}/`, config);
    return request.data;
}

export const postUser = async (form_data) => {
    const auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));

    const config = {
        headers: {
            'Authorization': `JWT ${auth_tokens.access}`,
            'Content-Type': 'application/json'
        }
    };

    // make request to get user
    const request = await axios.post(`${BASE_URL}/api/auth/register/`, form_data, config);
    return request.data;
}

export const updateUser = async (form_data) => {
    const auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));

    const config = {
        headers: {
            'Authorization': `JWT ${auth_tokens.access}`,
            'Content-Type': 'application/json'
        }
    };

    // make request to get user
    const request = await axios.patch(`${BASE_URL}/api/auth/user/${form_data.get('user_id')}/update-profile/`, form_data, config);
    return request.data;
}

export const deleteUser = async (id) => {
    const auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));

    const config = {
        headers: {
            'Authorization': `JWT ${auth_tokens.access}`,
            'Content-Type': 'application/json'
        }
    };

    // make request to get user
    const request = await axios.delete(`${BASE_URL}/api/auth/user/${id}/delete-profile`, config);
    return request.data;
}

