import { createContext, useContext } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient();
export const MainContext = createContext();

export const useMainAppContext = () => {
    return useContext(MainContext);
}


const MainAppProvider = ({ children }) => {
    const contextData = {
        queryClient
    };

    return ( 
        <MainContext.Provider value={contextData}>
            <QueryClientProvider client={queryClient}>
                { children }            
            </QueryClientProvider>
        </MainContext.Provider>
    );
}

export { MainAppProvider }